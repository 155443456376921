import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ListeAbsent = ({profileData, camp, school}) => {

  const [candidats, setCandidats] = useState([]);


  if(camp === null && school === null ){
    var ecole = profileData.profileData.urlData.ecole;
    var campus = profileData.profileData.urlData.campus
  
  }else{
    var campus = camp.toLowerCase();
    var ecole = school.toLowerCase();

  }
  
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URL_STRAPI+ecole+'s/', {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then((response) => {
        
        const currentDate = new Date().toISOString().split('T')[0]; 

        const filteredCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isAbsent = candidat.attributes.Statut === 'absent'; // Vérifie le statut "en attente"
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isAbsent  && campusPassage;
        });

        setCandidats(filteredCandidats);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, [campus, ecole]);

  
  //fonction pour réintegrer le candidat
  const  handleButtonBack = (idPresent) => {
    const newData = {
      data:  {
         Statut: null
      }
       
    };

    const url = process.env.REACT_APP_URL_STRAPI+ecole+'s/'
    const candidatID = idPresent;

    axios.put(url+candidatID, newData, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
      },
    })
    .then(response => {
        window.location.reload() 
    })
    .catch(error => {
        console.error('Erreur lors de la requête :', error);
    });
          
  };

  const DateDisplay = (dateTime) => {

    const dateObj = new Date(dateTime);

    const jour = ('0' + dateObj.getDate()).slice(-2);
    const mois = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    const annee = dateObj.getFullYear();
    const heure = ('0' + dateObj.getHours()).slice(-2);
    const minute = ('0' + dateObj.getMinutes()).slice(-2);
    
    return `${jour}/${mois}/${annee} ${heure}:${minute}`
  }


  return (
    <div>
        <TableContainer component={Paper} align='center'>
          <Typography component="h1" variant="h5" align="center">
            Liste des Absents 
          </Typography>   
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell >Numéro Parcoursup</StyledTableCell>
                <StyledTableCell  align="center">Nom du candidat</StyledTableCell>
                <StyledTableCell  align="center">Convocation</StyledTableCell> 
                <StyledTableCell  align="center">Horaire d'ouverture du livret</StyledTableCell>
                <StyledTableCell  align="center">Statut</StyledTableCell>
                <StyledTableCell  align="center">Réintégration</StyledTableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {candidats.map((candidat) => (
                <StyledTableRow key={candidat.attributes.parcoursup}>
                  <StyledTableCell component="th" scope="candidat">{candidat.attributes.parcoursup}</StyledTableCell>
                  <StyledTableCell align="center">{candidat.attributes.prenom} {candidat.attributes.nom}</StyledTableCell>
                  <StyledTableCell align="center">{candidat.attributes.convocation.split('T')[0]}</StyledTableCell>
                  <StyledTableCell align="center">{DateDisplay(candidat.attributes.OpenLivret)}</StyledTableCell>
                  <StyledTableCell align="center">{candidat.attributes.Statut}</StyledTableCell>
                  <StyledTableCell align="center">
                    <ButtonGroup  variant="contained">
                        <Button  onClick={() => handleButtonBack(candidat.id)}>Réintègrer</Button>
                    </ButtonGroup>
                  </StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
};

export default ListeAbsent;