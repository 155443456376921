import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const CompteurMath = (profileData) => {
  const [candidats, setCandidats] = useState([]);
  const [AbsentCandidats, SetAbsentCandidats] = useState([]);
  const [NoteCandidats, SetNoteCandidats] = useState([]);
  const [InProgressCandidats, SetInProgressCandidats] = useState([]);

  const campus = 'paris'
  

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URL_STRAPI+'stls', {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then((response) => {
        
        const currentDate = new Date().toISOString().split('T')[0]; 

        //tri des candidats du jours
        const filteredCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && campusPassage;
        });
                //tri des candidats du jours En absent
        const filteredAbsentCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isAbsent = candidat.attributes.StatutMath === 'absent'; // Vérifie le statut "absent"
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isAbsent && campusPassage;
        });

        //tri des candidats du jours noté
        const filteredNoteCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isNote = candidat.attributes.StatutMath === 'noté'; // Vérifie le statut "noté"
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isNote && campusPassage;
        });
        //tri des candidats du jours n'étant pas passé
        const filteredInProgressCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isNo = candidat.attributes.StatutMath === null || candidat.attributes.StatutMath === 'en cours';
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isNo && campusPassage;
        });

        SetInProgressCandidats(filteredInProgressCandidats)
        setCandidats(filteredCandidats);
        SetNoteCandidats(filteredNoteCandidats);
        SetAbsentCandidats(filteredAbsentCandidats)
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, [campus]);


  return (
    <div>
        <TableContainer component={Paper} align='center'>
          <Typography component="h1" variant="h5" align="center">
            Compteur candidats Mathematique
          </Typography>   
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell >Nombre de candidats Mathematique: </StyledTableCell>
                <StyledTableCell  align="center">Nom de candidats notés</StyledTableCell>
                <StyledTableCell  align="center">Nombre de candidats absent</StyledTableCell>
                <StyledTableCell  align="center">Reste à passer</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <StyledTableRow>
                    <StyledTableCell component="th" scope="candidat">{candidats.length}</StyledTableCell>
                    <StyledTableCell align="center">{NoteCandidats.length}</StyledTableCell>
                    <StyledTableCell align="center">{AbsentCandidats.length}</StyledTableCell>
                    <StyledTableCell align="center">{InProgressCandidats.length}</StyledTableCell>
                </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
};

export default CompteurMath;