import React from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export const WarningForm = ( {profileData} ) => {

    const theme = createTheme({
        palette: {
          primary: {
            main: '#ff0000', // Rouge
          },
        },
      });
        return (
            <ThemeProvider theme={theme}>
            <AppBar >
                <Typography sx={{ fontSize: 25, mr: 5 }} textAlign="center">Attention pensez à VALIDER ou ANNULER votre livret avant de fermer la page</Typography> 
            </AppBar>
            </ThemeProvider>
        );
   
};

export default WarningForm;