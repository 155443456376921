import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper } from '@mui/material';
import Math from './FinPassage/FinPassageMath'; 
import Motivation from './FinPassage/FinPassageMotivation';
import Biologie from './FinPassage/FinPassageBiologie';
import Anglais from './FinPassage/FinPassageAnglais';
 

const ListePassage = ({profileData}) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
   
        <Paper  style={{ maxWidth: 600, margin: '10px auto', padding: 20,  alignItems: 'center' }}>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Mathematique" />
                <Tab label="Motivation" />
                <Tab label="Biologie" />
                <Tab label="Anglais" />
            </Tabs>
        </Paper>
        <TabPanel value={selectedTab} index={1}>
                <Motivation profileData = { profileData }/>
        </TabPanel>
            <TabPanel value={selectedTab} index={0}>
                <Math profileData = { profileData }/>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <Biologie profileData = { profileData }/>
            </TabPanel>    
            <TabPanel value={selectedTab} index={3}>
                <Anglais profileData = { profileData }/>
            </TabPanel>
  
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default ListePassage;
