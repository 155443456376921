import React from 'react';
import CompteurMotivation from './Compteur/CompteurMotivation'; 
import CompteurMath from './Compteur/CompteurMath'; 
import CompteurAnglais from './Compteur/CompteurAnglais'; 
import CompteurBiologie from './Compteur/CompteurBiologie'; 



const Compteur = ({profileData}) => {

  return (
    <div>
        <CompteurMotivation profileData = { profileData }/>
        <CompteurMath profileData = { profileData }/>
        <CompteurAnglais profileData = { profileData }/>
        <CompteurBiologie profileData = { profileData }/>

    </div>
  );
};

export default Compteur;