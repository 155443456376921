import React from 'react';
import AbsenceMath from './ListeAbsent/ListeAbsentMath'; 
import AbsenceMotivation from './ListeAbsent/ListeAbsentMotivation';
import AbsenceBiologie from './ListeAbsent/ListeAbsentBiologie';
import AbsenceAnglais from './ListeAbsent/ListeAbsentAnglais';
 
const ListeAbsent = () => {
  
  return (
    <div>
      <AbsenceMath/>
      <AbsenceMotivation /> 
      <AbsenceBiologie />
      <AbsenceAnglais />
    </div>
  );
};



export default ListeAbsent;
