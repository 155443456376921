import React, { useState, useEffect } from 'react';
import { Container } from "@mui/material";
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { Grid, CardContent, Typography, TextField, InputLabel} from '@mui/material';
import { useLocation } from 'react-router-dom';



const InformationCandidat =  ( profileData ) => {

    // const ecole = profileData.profileData.profileData.urlData.ecole === "supbiotech-stl" ? "stl" : profileData.profileData.profileData.urlData.ecole;
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    const location = useLocation();
    const searchCamp = new URLSearchParams(location.search).get('campus')
    const searchSchool = new URLSearchParams(location.search).get('ecole')
    const searchID = new URLSearchParams(location.search).get('data')


    var mail = profileData.profileData.profileData.userData.mail ;

        if(searchCamp === 'null' && searchSchool === 'null' ){
      
            var ecole = profileData.profileData.profileData.urlData.ecole === "supbiotech-stl" ? "stl" : profileData.profileData.profileData.urlData.ecole;
            var campus = profileData.profileData.profileData.urlData.campus;
          
        
    
        }else{
            var campus = searchCamp.toLowerCase();
            var ecole = searchSchool.toLowerCase();
            
          
        }


   
    const [candidat, setCandidat] = useState([]);
    const [dateConvocation, setdateConvocation ]= useState("")

  useEffect(() => {
    // Récupérer les données depuis l'API avec Axios au chargement du composant

    
    axios.get(process.env.REACT_APP_URL_STRAPI+ecole+'s/'+searchID, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then(response => {
        setCandidat(response.data.data.attributes);
      
        const dateObj = new Date(response.data.data.attributes.convocation);

        const jour = ('0' + dateObj.getDate()).slice(-2);
        const mois = ('0' + (dateObj.getMonth() + 1)).slice(-2);
        const annee = dateObj.getFullYear();
        const heure = ('0' + dateObj.getHours()).slice(-2);
        const minute = ('0' + dateObj.getMinutes()).slice(-2);

        setdateConvocation(`${jour}/${mois}/${annee} ${heure}:${minute}`);
  

      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, [ecole, searchID, dateConvocation]);

    return (
        
        <Container align="center">
            <Grid container  spacing={3}>       
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            N° Parcoursup
                        </Typography>
                        <Typography variant="body2" component="p">
                            {candidat.parcoursup}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Candidat
                        </Typography>
                        <Typography variant="body2" component="p">
                        {candidat.civilite} {candidat.prenom} {candidat.nom}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Série
                        </Typography>
                        <Typography variant="body2" component="p">
                            {candidat.Serie}
                        </Typography>
                    </CardContent>
                </Grid>
                <Grid key={candidat.id} item xs={12} sm={6} md={3}>   
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            Convocation
                        </Typography>
                        <Typography variant="body2" component="p">
                            {dateConvocation}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
            
            <Box sx={{ width: '100%' }} >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux EPITA: {candidat.vepita}</Item>
                    </Grid>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux IPSA: {candidat.vipsa}</Item>
                    </Grid>
                    <Grid item xs={2}>
                        <Link to={candidat.lien_teams} target="_blank">
                            <GroupsIcon />
                        </Link>
                    </Grid>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux SupBiotech : {candidat.vsupbio}</Item>
                    </Grid>
                    <Grid item xs={5}>
                        <Item align='left'>Voeux ESME: {candidat.vesme}</Item>
                    </Grid>
                </Grid>
            </Box>
            <br></br>
            <Box sx={{ width: '100%' }} >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={4}>
                        <Item>Spé A : {candidat.SpeA}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>Spé B : {candidat.SpeB}</Item>
                    </Grid>
                    <Grid item xs={4}>
                        <Item>Spé abandonnée en 1ère : {candidat.Spe_abandonne}</Item>
                    </Grid>
                </Grid>
            </Box>
        <br></br>
            <Box sx={{ width: '70%' }} >
                <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <TextField
                        id="filled-helperText"
                        label="Grand classé ?"
                        value={candidat.Classe === true? "Oui" : "Non" }
                        variant="filled"
                        disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth  variant="filled">
                            <InputLabel htmlFor="filled-adornment-amount">Type d'oral</InputLabel>
                            <FilledInput
                                id="filled-adornment-amount"
                                startAdornment={<InputAdornment position="start">{candidat.OralType}</InputAdornment>}
                                disabled
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default InformationCandidat;