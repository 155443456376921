import React, { useEffect, useState,useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { SignOutButton } from "../SignOutButton";

import { msalInstance } from "../../index";
import { loginRequestTeams} from "../../authConfig";
import { InfosUserContext } from '../../InfosUserContext';
//import * as microsoftTeams from "@microsoft/teams-js";




const settings = [ 'Administration', 'Livret Motivation', 'Livret Biologie', 'Livret Anglais', 'Livret Mathématique'];

function MenuSTL({ Name,Mail, Ecole, Campus } ) {

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState('');
  const { userData } = useContext(InfosUserContext);
  

  useEffect(() => {
    async function recupToken(){
        if (!accessToken) {
          const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequestTeams,
            account: account
        });
        setAccessToken(response.accessToken);
      }
    }
    recupToken() ;
  }, [accessToken]);


  const openSharePointSite = () => {
    
    let ecoleLieu = '' ;
    
    if(Ecole === "supbiotech-stl") {
        ecoleLieu = 'SUP%27BIOTECHSTL' ;
    }else{
      ecoleLieu = Ecole.toUpperCase()+Campus.charAt(0).toUpperCase() + Campus.slice(1);
    }

        
    const sharePointSiteUrl = process.env.REACT_APP_URL_SHAREPOINT + ecoleLieu ;  
    window.open(sharePointSiteUrl, '_blank');

  };
 
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (setting) => {

    handleCloseUserMenu();

    if(setting === 'Administration'){
      navigate('/administrationSTL');
    }else if(setting === 'Livret Motivation') {
        navigate('/candidats');
    }else if(setting === 'Livret Biologie') {
        navigate('/biologie');
    }else if(setting === 'Livret Anglais') {
        navigate('/anglais');
    }else if(setting === 'Livret Mathématique') {
        navigate('/math');
    }
  };

  return (
    <AppBar >
    {accessToken ? (
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Typography sx={{ fontSize: 13,mr: 5 }} textAlign="center"><SignOutButton /></Typography> 
            <Box sx={{ flexGrow: 0 }}>
                <IconButton  onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Logo Concours Advance" src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ8EYQBopDKjeJE9uHqMuI0ZEi00wkiUL_OeLIwK3xs3ov5zIfd" style={{ width: '100px', height: '100%'}} />
                </IconButton>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                    {settings.map((setting) => (
                        <MenuItem key={setting} onClick={() => handleMenuItemClick(setting)}>
                          <Typography textAlign="center">{setting}</Typography> 
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Box  >
                <Typography sx={{ fontSize: 13 }}>
                    Bienvenue {`${Name}`}
                </Typography>
                <Typography sx={{ fontSize: 10 }}>
                  ( {`${Mail}`} )
                </Typography>
              </Box>

              <Box sx={{ ml: 10}}>
              <IconButton onClick={openSharePointSite}   sx={{ fontSize: 18 }} color="inherit">Outils Microsoft</IconButton>

            </Box>
        </Toolbar>
      </Container>
      ) : (
        <button onClick={() => msalInstance.loginRedirect()}>Se connecter</button>
      )}
    </AppBar>
  );
}
export default MenuSTL;