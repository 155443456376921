import React from 'react';
import { Container } from "@mui/material";
import InformationCandidat from '../component/InformationCandidat';
import FormMotivation from '../component/FormMotivation';
import { useLocation } from 'react-router-dom';


const Motivation = ({campus, ecole, profileData}) => {
  const location = useLocation();
  const searchID = new URLSearchParams(location.search).get('data');
  
    return (
    <Container align="center">
        <div style={{ margin: '50px' }}></div> {/* Espacement */}
        <InformationCandidat searchID = {searchID} profileData= { profileData } camp = {campus} school = {ecole}/>
        <FormMotivation searchID ={searchID} profileData={profileData}  camp = {campus} school = {ecole}/>   
    </Container>
  );
};

export default Motivation;
