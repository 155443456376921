import React, { createContext, useContext, useState } from 'react';

export const InfosUserContext = createContext();

export const InfosUserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [groupData, setGroupData] = useState(null);

  return (
    <InfosUserContext.Provider value={{ userData, groupData, setUserData, setGroupData }}>
      
      {children}
    </InfosUserContext.Provider>
  );
};