import { useEffect, useState } from "react";

import { useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

import { MsGraphApiCallGroup } from "./MsGraphApiCallGroup";

// Récupère les informations du compte connecté (Groupes + infos persos)
export const Profile = ({ onProfileData }) => {
  const { instance, inProgress } = useMsal();
  const [graphDataGroup, setGraphDataGroup] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!graphDataGroup && inProgress === InteractionStatus.None) {
          const response = await MsGraphApiCallGroup() ;
          // graphDataGroup est utilisé pour s'assurer qu'on a des données en retour de MsGraphApiCallGroup
          setGraphDataGroup(response);
          // Appeler la fonction de rappel avec les données
          onProfileData(response);
        }
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
        
          // console.log("ACCESS TOKEN PROFILE") ;
          await instance.acquireTokenRedirect({
            ...loginRequest,
            account: instance.getActiveAccount()
          });
        }
      }
    };

    fetchData();
  }, [inProgress, graphDataGroup, instance, onProfileData]) ;
  return (
    <div>
      {/* */}
    </div>
  );
};