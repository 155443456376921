import { loginRequest, graphConfigGroup, graphConfig } from "../authConfig";
import { msalInstance } from "../index";

import { useContext } from "react";
import { InfosUserContext } from '../InfosUserContext';

// Fetch les userInfos
let cachedAccessToken = null; // localStorage.getItem('accessToken') ; //
export async function MsGraphApiCallGroup() {
    if (!cachedAccessToken || isTokenExpired(cachedAccessToken)) {
        cachedAccessToken = await getNewAccessToken();
    }
    const headers = new Headers();
    const bearer = `Bearer ${cachedAccessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    const userResponse = await fetch(graphConfig.graphMeEndpoint, options) ;
    const userData = await userResponse.json();
    const groupResponse = await fetch(graphConfigGroup.graphMeEndpointGroup, options) ;
    const groupData = await groupResponse.json();

   // Utilisez le contexte pour mettre à jour les données
   // Quand j'ajoute le contexte ici il mouline indéfiniment sur la ProgressBar
    // const { setUserData, setGroupData } = useContext(InfosUserContext);
    // setUserData(userData);
    // setGroupData(groupData);

    const userInfos = {
        accessToken: cachedAccessToken,
        userData: userData,
        groupData: groupData
    };
    
    return userInfos;
}

 // Fonction pour vérifier si un token est expiré
 function isTokenExpired(token) {
    const expirationDate = new Date(token.expiresOn);
    return expirationDate < new Date();
  }
  
  // Fonction pour obtenir un nouvel accessToken
  async function getNewAccessToken() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error("Aucun compte actif !");
    }
  
    // console.log("ACCESS TOKEN MS-GRAPH-API-CALL");
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
  
    return response.accessToken;
  }