import React, { useState} from 'react';
import { Container, FormHelperText  } from "@mui/material";
import axios from 'axios';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Grid,TextField, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button} from '@mui/material';
import { useLocation } from 'react-router-dom';

const FormMotivation = ({profileData}) => {

    const [selectedNumber, setSelectedNumber] = useState('');   
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedStatut, setSelectedStatut] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [comment, setComment] = useState('');
    const [error, setError] = useState(false); 
    const navigate = useNavigate();

 
    const examinateur = profileData.profileData.userData.displayName;
    const options = ["EPITA","EPITA PAR","EPITA Anglophone","ESME","ESME Anglophone","IPSA","IPSA Anglophone","SUPBIOTECH","SUPBIOTECH Anglophone"];
    const numberOptions = ["1","2","3","4","5","6","7","8","9","12","13","14","15","16","17","18","19","20"];
    const statut = ['Oui', 'Non'];


    const location = useLocation();
    const searchCamp = new URLSearchParams(location.search).get('campus')
    const searchSchool = new URLSearchParams(location.search).get('ecole')
    const searchID = new URLSearchParams(location.search).get('data')

    var mail = profileData.profileData.userData.mail ;
    
    if(searchCamp === 'null' && searchSchool === 'null' ){
        var ecole = profileData.profileData.urlData.ecole === "supbiotech-stl" ? "stl" : profileData.profileData.urlData.ecole;
        var campus = profileData.profileData.urlData.campus;
      }else{
        var campus = searchCamp.toLowerCase();
        var ecole = searchSchool.toLowerCase();
      }


    const handleSelectChange = (event) => {
        setSelectedOptions(event.target.value);       
    };

    const handleNumberChange = (event) => {
        setSelectedNumber(event.target.value);
    };

    const handleStatutChange = (event) => {
        setSelectedStatut(event.target.value);
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const handleConfirmation = (proceed) => {
        setOpenConfirmation(false);
        
        if (proceed) {
            const sendStatut = {
                data:  {
                   Statut: null
                }      
              };

            axios.put(process.env.REACT_APP_URL_STRAPI+ecole+'s/'+searchID, sendStatut, {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
                },
            })
            .then(response => {
                navigate(`/candidats?campus=${encodeURIComponent(campus)}&ecole=${encodeURIComponent(ecole)}`);
            })
            .catch(error => {
                console.error('Erreur lors de la requête :', error);
            });
        }
    };
    
    const handleAnnulationButton = () => {
        setOpenConfirmation(true); 
    };

    const handleSubmit = (event) => {
        
        event.preventDefault(); 
        if (!selectedOptions.length || !selectedNumber || !selectedStatut || !comment) {
            setError(true); 
            return;
        }

        const newData = {
             data:  {
                Examinateur : examinateur,
                ecole_choix: selectedOptions.toString() ,
                CA: selectedStatut === 'Oui' ? true : false ,
                commentaire: comment,
                note: selectedNumber,
                Statut: 'noté'
             }
              
        };

        const url = process.env.REACT_APP_URL_STRAPI+ecole+'s/'+searchID
        

        axios.put(url, newData, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
            },
        })
        .then(response => {
            navigate(`/candidats?campus=${encodeURIComponent(campus)}&ecole=${encodeURIComponent(ecole)}`);
            
        })
        .catch(error => {
            console.error('Erreur lors de la requête :', error);
        });
    }

    return (
    
    <Container align="center">
        {/* créer un composant pour lui seul, car lorsque tu ajouteras un map sur chaque voeux ca risque de grossir la page  */}
        <Box sx={{ width: '100%' }} >
            <Grid container rowSpacing={1}>
                <Grid item xs={8}>
                    <FormControl required sx={{ m: 1, minWidth: 700 }}>
                        <InputLabel>Quelles écoles au sein du Concours Advance souhaitez-vous présenter ?</InputLabel>
                        <Select
                            multiple
                            label="Quelles écoles au sein du Concours Advance souhaitez-vous présenter ?"
                            value={selectedOptions}
                            onChange={handleSelectChange}
                            renderValue={(selected) => selected.join(', ')}
                            error={error && !selectedOptions.length} 
                        >
                            {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                <Checkbox checked={selectedOptions.includes(option)} />
                                <ListItemText primary={option} />
                            </MenuItem>
                            ))}
                        </Select>
                        {error && !selectedOptions.length && <FormHelperText>Ce champ est obligatoire</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Motivation Coeff 7" placement="top">
                        <FormControl required sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel>Note</InputLabel>
                            <Select
                                value={selectedNumber}
                                onChange={handleNumberChange}
                                label="Note"
                                error={error && !selectedNumber} 
                            >
                                {numberOptions.map((number) => (
                                <MenuItem key={number} value={number}>
                                    {number}
                                </MenuItem>
                                ))}                    
                            </Select>
                            {error && !selectedNumber && <FormHelperText>Ce champ est obligatoire</FormHelperText>}
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item xs={2}>
                    <Tooltip title="Séléctionnez Oui si le dossier du candidat nécessite plus d'attention" placement="top">
                        <FormControl required sx={{ minWidth: 120 }}>
                        <InputLabel>Case C.A</InputLabel>
                            <Select
                            
                                value={selectedStatut}
                                onChange={handleStatutChange}
                                label="Case C.A"
                                error={error && !selectedStatut}
                            >
                                {statut.map((ca) => (
                                <MenuItem key={ca} value={ca}>
                                    {ca}
                                </MenuItem>
                                ))}
                            </Select>
                            {error && !selectedStatut && <FormHelperText>Ce champ est obligatoire</FormHelperText>}
                        </FormControl>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
        <TextField
            label="Commentaires*"
            multiline
            rows={12}
            value={comment}
            onChange={handleCommentChange}
            variant="outlined"
            fullWidth
            error={error && !comment}
        />
        {error && !comment && <FormHelperText>Ce champ est obligatoire</FormHelperText>}
        <Box>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={6}>
                        <Button onClick={handleAnnulationButton}  color="error" variant="outlined" sx={{ mt: 2 }}>
                            Annuler
                        </Button>
                        <Dialog open={openConfirmation} onClose={() => handleConfirmation(false)}>
                            <DialogTitle>ATTENTION</DialogTitle>
                            <DialogContent>
                                <p>Êtes-vous sûr de vouloir annuler ?</p>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => handleConfirmation(false)} color="primary">
                                    Non
                                </Button>
                                <Button onClick={() => handleConfirmation(true)} color="primary">
                                    Oui
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>
                    <Grid item xs={6}>
                        <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>
                            Valider
                        </Button>
                    </Grid>

            </Grid>
        </Box>
    </Container>
  );
};

export default FormMotivation;
