import React, { useEffect, useState,useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { SignOutButton } from "../SignOutButton";
import { useNavigate } from 'react-router-dom';
import { msalInstance } from "../../index";
import {  loginRequestTeams} from "../../authConfig";
import { InfosUserContext } from '../../InfosUserContext';

function AdminMenu({ Name , Mail, Ecole, Campus}) {

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [accessToken, setAccessToken] = useState('');
  const { userData } = useContext(InfosUserContext);
  const navigate = useNavigate();


  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };

  const handleCampusSelection = (page) =>{
    if(page === "Livret"){
      navigate('/candidats');
    }else{
      navigate('/administration');
    }
  };

  useEffect(() => {
    async function recupToken(){
        if (!accessToken) {
          const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequestTeams,
            account: account
        });
        setAccessToken(response.accessToken);
      }
    }
    recupToken() ;
  }, [accessToken]);

  const openSharePointSite = () => {
    
    let ecoleLieu = '' ;
    
    if(Ecole === "supbiotech-stl") {
        ecoleLieu = 'SUP%27BIOTECHSTL' ;
    }else if(Campus === "strasbourg"){
      ecoleLieu = Ecole.toUpperCase()+'-'+Campus.charAt(0).toUpperCase() + Campus.slice(1);
    }else{
      ecoleLieu = Ecole.toUpperCase()+Campus.charAt(0).toUpperCase() + Campus.slice(1);
    }
  
        
    const sharePointSiteUrl = process.env.REACT_APP_URL_SHAREPOINT + ecoleLieu ;  
    window.open(sharePointSiteUrl, '_blank');

  };


  return (
    <AppBar >
      {accessToken ? (
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Typography sx={{ fontSize: 13, mr: 5 }} textAlign="center"><SignOutButton /></Typography> 
            <Box sx={{ flexGrow: 0 }}>      
                <IconButton  sx={{ p: 0 }}>
                    <Avatar alt="Logo Concours Advance" src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ8EYQBopDKjeJE9uHqMuI0ZEi00wkiUL_OeLIwK3xs3ov5zIfd"  style={{ width: '100px', height: '100%' }}/>
                </IconButton>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >

                </Menu>
            </Box>
            <Box sx={{ ml: 20 , pr: 20 }}>

                <Typography sx={{ fontSize: 13 }}>
                  Bienvenue  {`${Name}`}
                </Typography>
                <Typography sx={{ fontSize: 10 }}>
                 ({`${Mail}`})
                </Typography>
            </Box>

            <Box sx={{ ml: 10 }}>
            <Button
              aria-controls="menu-campus-livret"
              aria-haspopup="true"
              onClick={() => handleCampusSelection( "Administration")}
              color="inherit"
            >
              Administration
            </Button>
            </Box>
            <Box  />

            <Box>
            <Button
              aria-controls="menu-campus"
              aria-haspopup="true"
              color="inherit"
              onClick={() => handleCampusSelection("Livret")}
            >
              Livret Motivation
            </Button>
          </Box>

          <Box sx={{ flexGrow:1}} />
            <Box>
            <IconButton onClick={openSharePointSite}   sx={{ fontSize: 18 }} color="inherit">Outils Microsoft</IconButton>

          </Box>
        </Toolbar>
      </Container> 
      ) : (
        <button onClick={() => msalInstance.loginRedirect()}>Se connecter</button>
      )}
    </AppBar> 
  );
}
export default AdminMenu;