import React from 'react';
import { Container } from "@mui/material";
import InfoCandidatSTL  from '../../../component/STL/information_Form/InfoCandidatSTL'
import FormAnglais from '../../../component/STL/Form/FormAnglais'
import { useLocation } from 'react-router-dom';
import ErrorPage from '../../Error';

const AnglaisSTL = (profileData) => {
  const location = useLocation();
  const searchID = new URLSearchParams(location.search).get('data');

  const mail = profileData.profileData.profileData.userData.mail ;
  const containsAnglais = mail.includes('.anglais.');
  const containsAdmin = mail.includes('admin.');
  const containsSuperAdmin = mail.includes('superadmin-adv');

  if (containsAdmin || containsAnglais || containsSuperAdmin) {
    return (
      <Container align="center">
        <div style={{ margin: '50px' }}></div> {/* Espacement */}
        <InfoCandidatSTL  searchID={searchID} profileData={profileData}/>
        <FormAnglais  searchID={searchID} profileData={profileData}/>
      </Container>
    );
  
  } else {
    return  <ErrorPage/>
  }
};

export default AnglaisSTL;
