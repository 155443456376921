import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate} from 'react-router-dom';
import ErrorPage from '../../Error';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));


const Candidat_BiologieSTL = (profileData) => {
    const [candidats, setCandidats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    
    const mail = profileData.profileData.profileData.userData.mail ;
    const campus = profileData.profileData.profileData.urlData.campus

    const containsBiologie = mail.includes('.biologie.');
    const containsAdmin = mail.includes('admin.');
    const containsSuperAdmin = mail.includes('superadmin-adv');

  
    useEffect(() => {

      axios
        .get(process.env.REACT_APP_URL_STRAPI+'stls/?populate=*', {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
          },
        })
        .then((response) => {
        
          const currentDate = new Date().toISOString().split('T')[0]; 

            const filteredAbsentCandidats = response.data.data.filter((candidat) => {
            const candidatConvocation = candidat.attributes.convocation.split('T')[0];
            const isAbsent = candidat.attributes.StatutBiologie === null;
            const campusPassage = candidat.attributes.campus === 'paris';
            return candidatConvocation === currentDate && isAbsent && campusPassage;
          });
          setCandidats(filteredAbsentCandidats);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    }, [campus]);


    //fonction qui récupère l'id de l'etudiant et qui renvoi vers sont livret de motivation 
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };


    const handleButtonPresent = (idPresent) => {

      axios.get(process.env.REACT_APP_URL_STRAPI+'stls/'+ idPresent, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then(response => {

        const result  = response.data.data.attributes.StatutBiologie === 'en cours'? true : false;

        if(result === false){
          const dataStatut = {
            data:  {
              StatutBiologie: 'en cours',
              OpenLivretBiologie:  new Date()
            }      
          };
          SendInProgressStatut(idPresent, dataStatut);

        }else{
          setOpenModal(true);
        }

      })
      .catch(error => {
          console.error('Erreur lors de la requête :', error);
      });
    };

    const MyDialog = ({ open, onClose }) => {
      return (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Attention</DialogTitle>
          <DialogContent>
            <p>Le candidat sélectionné est actuellement en cours de passage.</p>
            <p> Êtes-vous sûr d'avoir sélectionné le bon candidat ?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      );
    };



    const SendInProgressStatut = (id, statut) => {


      axios.put(process.env.REACT_APP_URL_STRAPI+'stls/'+id, statut, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then(response => {
          navigate(`/LivretBiologie?data=${encodeURIComponent(id)}`);
      
      })
      .catch(error => {
          console.error('Erreur lors de la requête :', error);
      });
    }

   
    //fonction qui permet de mettre absent l'étudiant
    const handleButtonMissing = (idMissing) => {
      
      const newData = {
           data:  {
            StatutBiologie: 'absent'
           }
            
      };

      const candidatID = idMissing;

      axios.put(process.env.REACT_APP_URL_STRAPI+'stls/'+candidatID, newData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then(response => {
          window.location.reload()
      })
      .catch(error => {
          console.error('Erreur lors de la requête :', error);
      });

     
    }

    //fonction qui permet de filtrer avec la barre de recherche
    const filteredCandidats = candidats.filter((candidat) =>
      candidat.attributes.nom.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  
    if (containsAdmin || containsBiologie || containsSuperAdmin) {
      return (
        <Container >
          <div style={{ margin: '80px' }}></div> 
          <TableContainer component={Paper} align='center'>
            <Typography component="h1" variant="h5" align="center">
            Liste des candidats Biologie-Biochimie
            </Typography> 
            <TableRow>
            <TextField  type="text" id="search" label="Search" sx={{ width: 800, mt: 5, mb:5 }}  value={searchTerm} onChange={handleSearch} />
            </TableRow>    
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell >Numéro Parcoursup</StyledTableCell>
                  <StyledTableCell  align="center">Civilité</StyledTableCell>
                  <StyledTableCell  align="center">Nom du candidat</StyledTableCell>
                  <StyledTableCell  align="center">Série</StyledTableCell>
                  <StyledTableCell  align="center">Convocation</StyledTableCell> 
                  <StyledTableCell  align="center">Présence</StyledTableCell> 
                </TableRow>
              </TableHead>
              <TableBody>
              <MyDialog open={openModal} onClose={() => setOpenModal(false)} />
                {filteredCandidats.map((candidat) => (
                
                  <StyledTableRow key={candidat.attributes.parcoursup}>
                      <StyledTableCell component="th" scope="candidat">{candidat.attributes.parcoursup}</StyledTableCell>
                      <StyledTableCell align="center">{candidat.attributes.civilite}</StyledTableCell>
                      <StyledTableCell align="center">{candidat.attributes.nom} {candidat.attributes.prenom}</StyledTableCell>
                      <StyledTableCell align="center">{candidat.attributes.Serie}</StyledTableCell>
                      <StyledTableCell align="center">{candidat.attributes.convocation.split('T')[0]}</StyledTableCell>
                      <StyledTableCell align="center">
                          <ButtonGroup  variant="contained">
                              <Button  onClick={() => handleButtonPresent(candidat.id)}>Présent</Button>
                              <Button color="error" onClick={() => handleButtonMissing(candidat.id)}>Absent</Button>
                          </ButtonGroup>
                      </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      );
    } else {
      return  <ErrorPage/>
    }
  };
  
  export default Candidat_BiologieSTL;
  