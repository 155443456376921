import React from 'react';
import { Container } from "@mui/material";
import InfoCandidatSTL  from '../../../component/STL/information_Form/InfoCandidatSTL'
import FormBiologie from '../../../component/STL/Form/FormBiologie'
import { useLocation } from 'react-router-dom';
import ErrorPage from '../../Error';


const BiologieSTL = (profileData) => {
  const location = useLocation();
  const searchID = new URLSearchParams(location.search).get('data');

  const mail = profileData.profileData.profileData.userData.mail ;
  const containsBiologie = mail.includes('.biologie.');
  const containsAdmin = mail.includes('admin.');
  const containsSuperAdmin = mail.includes('superadmin-adv');

  if (containsAdmin || containsBiologie || containsSuperAdmin) {
      return (  
        <Container align="center">
          <div style={{ margin: '50px' }}></div> {/* Espacement */}
          <InfoCandidatSTL  searchID={searchID} profileData={profileData}/>
          <FormBiologie  searchID={searchID} profileData={profileData}/>  
        </Container>
      );
  } else {
    return  <ErrorPage/>
  }
};

export default BiologieSTL;
