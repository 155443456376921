import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import axios from 'axios';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import { InputLabel, Select, MenuItem, TextField, Checkbox, Grid, Button  } from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




const FormMath =  (data) => {

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [comment, setComment] = useState('');
  const navigate = useNavigate();
  const [note1, setNote1] = useState('');
  const [note2, setNote2] = useState('');
  const [note3, setNote3] = useState('');
  const [total1, setTotal1] = useState('');
  const [note4, setNote4] = useState('');
  const [note5, setNote5] = useState('');
  const [note6, setNote6] = useState('');
  const [total2, setTotal2] = useState('0');
  const [Notetotal, setNotetotal] = useState('0');
  const [niveau1, setNiveau1] = useState("");
  const [niveau2, setNiveau2] = useState("");
  const [bonusPoint, setBonusPoint] = useState(false);
  const [error, setError] = useState(false);

  const examinateur = data.profileData.profileData.profileData.userData.displayName;

  const handleCommentChange = (event) => {
      setComment(event.target.value);
  };

  const handleConfirmation = (proceed) => {
    setOpenConfirmation(false);
    if (proceed) {
        const sendStatut = {
            data:  {
              StatutMath: null
            }      
          };

        axios.put(process.env.REACT_APP_URL_STRAPI+'stls/'+data.searchID, sendStatut, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
          },
        })
        .then(response => {
            navigate('/math');
        })
        .catch(error => {
            console.error('Erreur lors de la requête :', error);
        });
    }
  };

const handleAnnulationButton = () => {
    setOpenConfirmation(true); 
};

const handleSubmit = (event) => {

  if (
    note1 === '' ||
    note2 === '' ||
    note3 === '' ||
    note4 === '' ||
    note5 === '' ||
    note6 === '' ||
    niveau1 === '' ||
    niveau2 === '' ||
    !comment
  ) {
    setError(true); // Définit l'état d'erreur à true s'il y a des champs non remplis
    return;
  }

  const newData = {
    data :  { 
      ExaminateurMath : examinateur,
      PointBonusMath: bonusPoint ,
      commentaireMath: comment,
      note_Math_ex1_resolution : note1,
      note_Math_ex2_resolution : note4,
      note_Math_ex1_connaissance : note2,
      note_Math_ex2_connaissance : note5,
      note_Math_ex1_coherence : note3,
      note_Math_ex2_coherence : note6,
      niveauEx1Math : niveau1,
      niveauEx2Math : niveau2,
      note_Math_ex1 : total1,
      note_Math_ex2 : total2,
      noteMath : Notetotal,
      StatutMath: 'noté'
    } 
  };
  


  const url = process.env.REACT_APP_URL_STRAPI+'stls/'+data.searchID

        axios.put(url, newData, {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
          },
        })
        .then(response => {
            navigate('/math'); 
        })
        .catch(error => {
            console.error('Erreur lors de la requête :', error);
        });
  
}

    useEffect(() => {
      setTotal1(Number(note1)+Number(note2)+Number(note3));
      setTotal2(Number(note4)+Number(note5)+Number(note6));
    
      }, [note1, note2, note3, note4, note5, note6,niveau1, niveau2]);

    useEffect(() => {
      setNotetotal(Number(total1)+Number(total2));
    }, [total1, total2]);

    useEffect(() => {
        setNotetotal(total1 + total2 + (bonusPoint && Notetotal <= 19 ? 1 : 0));
      }, [total1, total2, bonusPoint,Notetotal]);


  return (
    <div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="center">Résolution de l'exercice /5</StyledTableCell>
            <StyledTableCell align="center">Connaissance du cours associé /2</StyledTableCell>
            <StyledTableCell align="center">Cohérence,raisonnement, méthodologie /3</StyledTableCell>
            <StyledTableCell align="center">Difficulté</StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow>
              <StyledTableCell component="th" scope="row">
                exercice 1
              </StyledTableCell>
              <StyledTableCell align="center">
                <Select
                        value={note1}
                        onChange={(e) => setNote1(e.target.value)}
                        error={error && !note1}
                        >
                        {['0', '1', '2', '3', '4', '5'].map((value) => (
                            <MenuItem key={value} value={value}>
                            {value}
                            </MenuItem>
                        ))}
                    </Select>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Select
                    value={note2}
                    onChange={(e) => setNote2(e.target.value)}
                    error={error && !note2}
                    >
                    {['0', '1', '2'].map((value) => (
                        <MenuItem key={value} value={value}>
                        {value}
                        </MenuItem>
                    ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Select
                    value={note3}
                    onChange={(e) => setNote3(e.target.value)}
                    error={error && !note3}
                    >
                    {['0', '1', '2', '3'].map((value) => (
                        <MenuItem key={value} value={value}>
                        {value}
                        </MenuItem>
                    ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Select 
                value={niveau1} 
                onChange={(e) => setNiveau1(e.target.value)}
                error={error && !niveau1}
                >
                    {["", "Facile", "Moyen", "Difficile"].map((value) => (
                        <MenuItem key={value} value={value}>
                        {value}
                        </MenuItem>
                    ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                    label="Total"
                    value={total1}
                    InputProps={{
                    readOnly: true,
                    }}
                />
                </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                    exercice 2
                </StyledTableCell>
                <StyledTableCell align="center">
                    <Select
                        value={note4}
                        onChange={(e) => setNote4(e.target.value)}
                        error={error && !note4}
                        >
                        {['0', '1', '2', '3', '4', '5'].map((value) => (
                            <MenuItem key={value} value={value}>
                            {value}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <Select
                        value={note5}
                        onChange={(e) => setNote5(e.target.value)}
                        error={error && !note5}
                        >
                        {['0', '1', '2'].map((value) => (
                            <MenuItem key={value} value={value}>
                            {value}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <Select
                        value={note6}
                        onChange={(e) => setNote6(e.target.value)}
                        error={error && !note6}
                        >
                        {['0', '1', '2', '3'].map((value) => (
                            <MenuItem key={value} value={value}>
                            {value}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledTableCell>
                <StyledTableCell align="center">
                    <Select value={niveau2}
                    onChange={(e) => setNiveau2(e.target.value)}
                    error={error && !niveau2}
                    >
                        {["", "Facile", "Moyen", "Difficile"].map((value) => (
                            <MenuItem key={value} value={value}>
                            {value}
                            </MenuItem>
                        ))}
                    </Select>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <TextField
                      label="Total"
                      value={total2}
                      InputProps={{
                      readOnly: true,
                      }}
                  />
                  </StyledTableCell>

            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <Grid container rowSpacing={1}>
      <Grid item xs={10}>
        <StyledTableCell align="center">
          <Checkbox
            checked={bonusPoint}
            onChange={() => setBonusPoint(!bonusPoint)}
            color="primary"
          />
          <InputLabel>Ajouter un point bonus à la note finale</InputLabel>
        </StyledTableCell>
      </Grid>
      <Grid item xs={2}>
        <StyledTableCell align="center">
          <TextField
              label="Note Finale"
              value={Notetotal}
              InputProps={{
                  readOnly: true,
              }}
          />
        </StyledTableCell>
      </Grid>
    </Grid>
    <TextField
            label="Commentaires"
            multiline
            rows={12}
            value={comment}
            onChange={handleCommentChange}
            variant="outlined"
            fullWidth
            required
    />

    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
            <Button onClick={handleAnnulationButton}  color="error" variant="outlined" sx={{ mt: 2 }}>
                Annuler
            </Button>
            <Dialog open={openConfirmation} onClose={() => handleConfirmation(false)}>
                <DialogTitle>ATTENTION</DialogTitle>
                <DialogContent>
                    <p>Êtes-vous sûr de vouloir annuler ?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmation(false)} color="primary">
                        Non
                    </Button>
                    <Button onClick={() => handleConfirmation(true)} color="primary">
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
        <Grid item xs={6}>
            <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>
                Valider
            </Button>
        </Grid>
      </Grid>
      </Box>
    
    </div>
  );
}

export default FormMath;