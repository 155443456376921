import { LogLevel } from "@azure/msal-browser";

// Les différentes configurations qu'il faut passer à Msal lors de la création

export const msalConfig = {
    auth: {
        clientId: "a900769a-9f3f-4816-bd0b-b3c20da630d7",
        authority: "https://login.microsoftonline.com/6ca92191-baee-4ed3-abe4-0d8d6de60e70",
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    },
    cache: {
        cacheLocation: "localStorage",
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
// J'ai ajouté les scopes de Teams dans loginRequest comme celà on n'en gère qu'un
export const loginRequest = {
    scopes: ["User.Read", "GroupMember.Read.All", "TeamsActivity.Read", "TeamsTab.ReadWrite.All"]
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequestTeams = {
    scopes: ["User.Read", "TeamsActivity.Read", "TeamsTab.ReadWrite.All"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfigGroup = {
    graphMeEndpointGroup: "https://graph.microsoft.com/v1.0/me/memberOf"
};