import React, { useState } from 'react';
import { Tabs, Tab, Box, Paper } from '@mui/material';
import Compteur from '../component/STL/Administration/Compteur'; 
import FinPassage from '../component/STL/Administration/FinPassage'; 
import Absence from '../component/STL/Administration/ListeAbsent'; 
import EnCours from '../component/STL/Administration/ListePassage'; 
import Error from './Error';
import { useLocation } from 'react-router-dom';



const AdministrationSTL = ({profileData}) => {
  const [selectedTab, setSelectedTab] = useState(0);



  const location = useLocation();
  const searchCamp = new URLSearchParams(location.search).get('campus')
  const searchSchool = new URLSearchParams(location.search).get('ecole')
  


  if(searchCamp === null && searchSchool === null ){
    var mail = profileData.profileData.userData.mail ;
    var ecole = profileData.profileData.urlData.ecole
    var campus = profileData.profileData.urlData.campus;
    
  }else{
    var campus = searchCamp.toLowerCase();
    var ecole = searchSchool.toLowerCase();
    var mail = profileData.profileData.userData.mail ;
    
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  }; 


  const containsAdmin = mail.includes('admin.');
  const containsSuperAdmin = mail.includes('superadmin-adv');

  if (containsAdmin || containsSuperAdmin) {
      return (
        <div>
            <Paper elevation={3} style={{ maxWidth: 600, margin: '50px auto', padding: 20, justifyContent: 'center', alignItems: 'center' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="suivi du jour" />
                    <Tab label="Absents" />
                    <Tab label="Reste à passer" />
                    <Tab label="Terminés" />
                </Tabs>
            </Paper>
            <TabPanel value={selectedTab} index={0}>
                <Compteur profileData = { profileData } camp = {campus} school = {ecole}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Absence profileData = { profileData } camp = {campus} school = {ecole}/>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                <EnCours profileData = { profileData } camp = {campus} school = {ecole} />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
                <FinPassage profileData = { profileData } camp = {campus} school = {ecole}/>
            </TabPanel>
            
        </div>
      );
    } else {
      return <Error />
    }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;


    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );

}

export default AdministrationSTL;
