import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

  const defaultTheme = createTheme();

function Connexion () {

    
      return (
        <ThemeProvider theme={defaultTheme}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box mb={2}>
            <Typography variant="h4" align="center">
              Bienvenue sur le portail du concours Advance
            </Typography>
          </Box>
          <Typography variant="body1" align="center">
            Merci de visiter notre portail. Nous sommes ravis de vous accueillir !
          </Typography>
        </Box>
        </ThemeProvider>
      );
}
export default Connexion;