import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  Card, CardContent, InputLabel, Select, MenuItem,} from "@mui/material";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));


const FinPassage = ({profileData, camp, school}) => {


    const [candidats, setCandidats] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatut, setSelectedStatut] = useState('');
    const [comment, setComment] = useState('');
    const [selectedNumber, setSelectedNumber] = useState('');   
    const [formData, setFormData] = useState({});
    const [idCandidat, setIdCandidat] = useState(''); 
    const [dateConvocation, setdateConvocation ]= useState("")
    

    const adminChange = profileData.profileData.userData.displayName;

    if(camp === 'null' && school === 'null' ){
      var ecole = profileData.profileData.urlData.ecole;
      var campus = profileData.profileData.urlData.campus
    
    }else{
      var campus = camp.toLowerCase();
      var ecole = school.toLowerCase();

    }



    const numberOptions = Array.from({ length: 20 }, (_, index) => index + 1);
    const statut = ['','Oui', 'Non'];
  
    useEffect(() => { 
      axios
        .get(process.env.REACT_APP_URL_STRAPI+ecole+'s/', {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
          },
        })
        .then((response) => {

          const currentDate = new Date().toISOString().split('T')[0]; 

          const filteredNoteCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isNote = candidat.attributes.Statut === 'noté'; // Vérifie le statut "noté"
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isNote && campusPassage;
        });
          setCandidats(filteredNoteCandidats);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données:', error);
        });
    }, [campus,ecole]);


    const handleButtonPresent = (candidat) => {

        setIdCandidat(candidat);

        async function fetchData() {
            try {
              await axios.get(process.env.REACT_APP_URL_STRAPI+ecole+'s/'+candidat, {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
                },
              })
              .then(response => {
                setFormData(response.data.data.attributes);
                setComment(response.data.data.attributes.commentaire)

                const dateObj = new Date(response.data.data.attributes.convocation);

                const jour = ('0' + dateObj.getDate()).slice(-2);
                const mois = ('0' + (dateObj.getMonth() + 1)).slice(-2);
                const annee = dateObj.getFullYear();
                const heure = ('0' + dateObj.getHours()).slice(-2);
                const minute = ('0' + dateObj.getMinutes()).slice(-2);
        
                setdateConvocation(`${jour}/${mois}/${annee} ${heure}:${minute}`);
            
                
              })
                } catch (error) {
                    console.error('Une erreur s\'est produite :', error);
                }
            }
          fetchData();
        setOpenDialog(true);  
    };

    //gére l'envoi des données de la fenetre de dialogue
    const handleCloseDialog = (confirmation) => {

        if(confirmation === 'Annulé'){
            setOpenDialog(false);
        }else if(confirmation === 'Validé'){
          
   
          const newData = {
            data:  {
              CA: selectedStatut !== '' ? selectedStatut : undefined,
              commentaire: comment !== '' ? comment : undefined,
              note: selectedNumber !== '' ? selectedNumber : undefined,
              AdminChangeName : adminChange 
            }     
          };
          const url = process.env.REACT_APP_URL_STRAPI+ecole+'s/'+ idCandidat

          axios.put(url, newData, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
            },
          })
          .then(response => {
              setOpenDialog(false);
              setSelectedNumber('');
              setSelectedStatut('');
              setComment('');

          })
          .catch(error => {
              console.error('Erreur lors de la requête :', error);
          });
        }
        
    };

    //Focntion qui permet de récupérer les donnnées entré dans la fenetre de dialogue
    const handleNumberChange = (event) => {
      setSelectedNumber(event.target.value);
    };
    const handleStatutChange = (event) => {
      setSelectedStatut(event.target.value);
    };
    const handleCommentChange = (event) => {
      setComment(event.target.value);
  };
     
      //fonction qui récupère l'id de l'etudiant et qui renvoi vers sont livret de motivation 
    const handleSearch = (e) => {
      setSearchTerm(e.target.value);
    };
    //fonction qui permet de filtrer avec la barre de recherche
    const filteredCandidats = candidats.filter((candidat) =>
      candidat.attributes.nom.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  
    return (
      <Container >
        <TableContainer component={Paper} align='center'>
          <Typography component="h1" variant="h5" align="center">
           Liste des candidats notés
          </Typography> 
          <TableRow>
          <TextField  type="text" id="search" label="Search" sx={{ width: 800, mt: 5, mb:5 }}  value={searchTerm} onChange={handleSearch} />
          </TableRow>    
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell >Numéro Parcoursup</StyledTableCell>
                <StyledTableCell  align="center">Civilité</StyledTableCell>
                <StyledTableCell  align="center">Nom du candidat</StyledTableCell>
                <StyledTableCell  align="center">Convocation</StyledTableCell> 
                <StyledTableCell  align="center">Modifier</StyledTableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCandidats.map((candidat) => (
                <StyledTableRow key={candidat.attributes.parcoursup}>
                    <StyledTableCell component="th" scope="candidat">{candidat.attributes.parcoursup}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.civilite}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.nom} {candidat.attributes.prenom}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.convocation.split('T')[0]}</StyledTableCell>
                    <StyledTableCell align="center">
                        <Button  onClick={() => handleButtonPresent(candidat.id)}>Modifier</Button> 
                    </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="lg">
            <DialogTitle>Modifier le candidat</DialogTitle>
            <DialogContent>
                <DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Card variant="outlined">
                          <CardContent>
                              <Typography variant="h6" component="div">
                              {formData.civilité}  Nom: {formData.nom} {formData.prenom}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Numéro Parcoursup: {formData.parcoursup}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Date de Convocation: {dateConvocation}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Note: {formData.note}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Commentaires: {formData.commentaire}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                  Case C.A: {formData.CA === true ? 'Oui': 'Non'}
                              </Typography>
                          </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={8}>
                    <Card variant="outlined">
                          <CardContent>
                          <Grid>
                            <FormControl  sx={{ m: 1, minWidth: 120 }} >
                            <InputLabel>Note</InputLabel>
                                <Select
                                    value={selectedNumber}
                                    onChange={handleNumberChange}
                                    label="Note"
                                    required
                                >
                                    {numberOptions.map((number) => (
                                    <MenuItem key={number} value={number}>
                                        {number}
                                    </MenuItem>
                                    ))}                    
                                </Select>
                            </FormControl>
                          </Grid>
                          <Grid>
                            <FormControl sx={{ minWidth: 120 }} style={{ margin: '7px' }}>
                            <InputLabel>Case C.A</InputLabel>
                                <Select
                                  
                                    value={selectedStatut}
                                    onChange={handleStatutChange}
                                    label="Case C.A"
                                >
                                    {statut.map((ca) => (
                                    <MenuItem key={ca} value={ca === 'Oui' ? true : false}>
                                        {ca}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                          </Grid>
                          <TextField
                            label="Commentaires"
                            multiline
                            rows={4}
                            value={comment}
                            onChange={handleCommentChange}
                            variant="outlined"
                            fullWidth 
                          />
                          </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                </DialogContentText>
                {/* Ajoutez ici votre formulaire avec les champs de modification */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDialog('Annulé')} color="primary">
                    Annuler
                </Button>
                <Button onClick={() => handleCloseDialog('Validé')} color="primary">
                    Valider
                </Button>
            </DialogActions>
        </Dialog>
      </Container>
    );
  };
  
  export default FinPassage;
  