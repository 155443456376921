import {
  Routes,
  Route,
  useLocation, 
} from 'react-router-dom';
import { useState, useContext } from "react";

import { Menu } from "./component/Menu";
import Warning from "./component/WarningForm";
import { Loading } from "./component/Loading";
import { ProgressBar } from "./component/ProgressBar";
import { ErrorComponent } from "./component/ErrorComponent";
import {ErrorPage} from "./views/Error";

import { Profile } from './component/Profile';
import { Logout } from './component/Logout';

import Candidat from './views/Candidat';
import Connexion from './views/Connexion';
import Motivation from './views/Motivation';

import { MsalProvider } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { InfosUserContext } from './InfosUserContext';
import Administration from './views/Administration';
import AdministrationSTL from './views/AdministrationSTL';
import CandidatSTLMath from './views/STL/CandidatList/Candidat_MathSTL';
import CandidatSTLAnglais from './views/STL/CandidatList/Candidat_AnglaisSTL';
import CandidatSTLBiologie from './views/STL/CandidatList/Candidat_BiologieSTL';
import LivretAnglaisSTL from './views/STL/Livret/AnglaisSTL';
import LivretBiologieSTL from './views/STL/Livret/BiologieSTL';
import LivretMathSTL from './views/STL/Livret/MathSTL';
import ResultatGeneraux from './views/ResultatGeneraux';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const CenteredContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const ContentContainer = styled(Box)`
  max-width: 600px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const StyledButton = styled(Button)`
  && {
    background-color: #007bff;
    color: #ffffff;
    border-radius: 4px;
    margin-top: 16px;
    &:hover {
      background-color: #0056b3;
    }
  }
`;

const App = ({pca }) => {

  const { setUserData, setGroupData } = useContext(InfosUserContext);
  const [data, setData] = useState(null); // Les données provenant de Profile
  const [hasGroupADVResGen, setHasGroupADVResGen] = useState(null);
  const [loading, setLoading] = useState(true);

  const authRequest = {
    ...loginRequest
  };

  const handleProfileData = (profileData) => {
    
    // Démarrez le chargement
    setLoading(true);
   // console.log("RETOUR DE PROFILE : ", profileData);    // Traitement des données renvoyées par le composant Profile
    setData(profileData);// Mise à jour de profileData avec les données reçues de Profile
    setUserData(profileData.userData);
 
    const mail = profileData.userData.mail; 

    const parts = mail.split('.'); //split le mail a partir des points et met l'école et le campus dans les variables
    const ecole = parts[1];
    const campus = parts[2];

    const dataEcole = 
      { ecole: ecole, campus: campus }
    ;

    profileData.urlData = dataEcole;
    setGroupData(profileData.groupData); 
    // On va vérifier si l'utilisateur appartient à certains groupes
    // Par exemple, à "ADV Résultats Généraux" et peut-être à d'autres... à voir
    // J'ai renommé ADV Résultats Généraux en ADV Resultats Generaux
    // Vérifiez si un élément du tableau à la valeur du groupe
    //const searchText = "ADV SUPERADMINS" ;
    //const searchText = "Loop Group" ;
    const searchText = "ADV Resultats Generaux" ;
    const res = profileData.groupData.value.some(item => item.displayName === searchText) ;

    setHasGroupADVResGen(res) ; 
    setLoading(false);

  };

  
  return (
    
    <MsalProvider instance={pca}>
  
      <div className="app">
          <Profile onProfileData={handleProfileData}  />
          {/* Conteneur pour l'espace central */}
          <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
            errorComponent={ErrorComponent} 
            loadingComponent={Loading}
        >
          <div className="content">
           {loading ? (
              <ProgressBar />
            ) : (
              <div>
                {
                data && data.userData && (data.userData.mail.startsWith("jury") || data.userData.mail.startsWith("admin") || hasGroupADVResGen) ? (
                  <Pages profileData={ data } />
                  ) : (
                    // Erreur de connexion -> Alerte
                    <CenteredContainer>
                      <ContentContainer>
                        <Typography variant="h5" gutterBottom>
                          N'auriez-vous pas utilisé votre adresse mail Outlook personnelle ?
                        </Typography>

                        {/* Placeholder for data.userData check */}
                        <Typography variant="body1">
                          {data && data.userData && (
                            <>
                              Votre adresse e-mail {data.userData.mail} n'est pas reconnue.
                            </>
                          )}
                        </Typography>

                        <Typography variant="body1" gutterBottom>
                          Vous devez utiliser votre adresse mail ADVANCE au format :
                        </Typography>

                        <Typography variant="body1">
                          jury.ipsa.paris.motivation.7@ionis-group.com
                          OU admin.esme.lille.10@ionis-group.com
                        </Typography>

                        <StyledButton variant="contained" onClick={() => pca.logoutRedirect()}>
                          Cliquez ici pour vous déconnecter et vous reconnecter avec le bon compte.
                        </StyledButton>
                      </ContentContainer>
                    </CenteredContainer>
                  )}
                </div>
              )}
          </div>
            </MsalAuthenticationTemplate>
      </div>
      </MsalProvider>
    );
  };

  export default App;

  const Pages = (profileData) => {
    const location = useLocation();
    return (

      
      <div style={{ display: "flex" }}>

          {location.pathname.includes('/Motivation') || location.pathname.includes('/LivretAnglais') || location.pathname.includes('/LivretBiologie') || location.pathname.includes('/LivretMath') ? (
                <div style={{ display: "flex" }}>
                  <Warning />
                </div>
              ) : (
                <Menu profileData = { profileData } />
          )}
        


        <div style={{ flex: 1, padding: "20px" }}>
            <Routes>
            
              <Route path="/logout" element={<Logout />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route exact path='/' element={< Connexion profileData = { profileData }/>}></Route>

              { profileData.profileData.userData.mail.includes("motivation") && (
                <>
                <Route exact path='/candidats' element={< Candidat profileData = { profileData }/>}></Route>
                <Route exact path='/motivation' element={< Motivation profileData = { profileData }/>}></Route>
                </>
              )}
                                                                                                            
              {profileData.profileData.userData.mail.includes('.supbiotech-stl.') || profileData.profileData.userData.mail.startsWith("superadmin-adv")   /* attention ici changer en super*/? (
                <>
                <Route exact path='/math' element={< CandidatSTLMath profileData = { profileData }/>}></Route>
                <Route exact path='/anglais' element={< CandidatSTLAnglais profileData = { profileData }/>}></Route>
                <Route exact path='/biologie' element={< CandidatSTLBiologie profileData = { profileData }/>}></Route>
                <Route exact path='/livretMath' element={< LivretMathSTL profileData = { profileData }/>}></Route>
                <Route exact path='/livretAnglais' element={< LivretAnglaisSTL profileData = { profileData }/>}></Route>
                <Route exact path='/livretBiologie' element={< LivretBiologieSTL profileData = { profileData }/>}></Route>
                <Route exact path='/candidats' element={< Candidat profileData = { profileData }/>}></Route>
                <Route exact path='/motivation' element={< Motivation profileData = { profileData }/>}></Route>   
                <Route exact path='/administrationSTL' element={<AdministrationSTL profileData = { profileData }/>}></Route> 
                            
                </>
              ):(
                <Route path="/error" element={<ErrorPage />} />

              )}

              {profileData.profileData.userData.mail.startsWith("admin") || profileData.profileData.userData.mail.startsWith("superadmin-adv") ? (
                <>
                <Route exact path='/administration' element={<Administration profileData = { profileData } />}></Route>
                <Route exact path='/candidats' element={< Candidat profileData = { profileData }/>}></Route>
                <Route exact path='/motivation' element={< Motivation profileData = { profileData }/>}></Route>
                </>
              ):(
                <Route path="/error" element={<ErrorPage />} />

              )}

                <Route exact path='/rg' element={<ResultatGeneraux profileData = { profileData } />}></Route>


              
            </Routes>
        </div>
    </div>
  
    );
  };

