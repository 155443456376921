import {Box, Typography  } from "@mui/material";

export const Loading = () => {
    return(   
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh" // Ajustez la hauteur selon vos besoins
    >
        <Typography variant="h6" textAlign="center">
            Authentification en cours...
        </Typography>
    </Box>
    )
}