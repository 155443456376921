import React, { useEffect, useState,useContext } from 'react';
import axios from 'axios';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import { SignOutButton } from "../SignOutButton";
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';



import { msalInstance } from "../../index";
import {  loginRequestTeams} from "../../authConfig";
import { InfosUserContext } from '../../InfosUserContext';



function SuperAdminMenu({ Name , Mail}) {
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [ecole, setEcole] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [accessToken, setAccessToken] = useState('');
  const { userData } = useContext(InfosUserContext);

  const location = useLocation();
  const searchCamp = new URLSearchParams(location.search).get('campus')
  const searchSchool = new URLSearchParams(location.search).get('ecole')

  const livretType = ['Livret Motivation','Livret Biologie', 'Livret Anglais', 'Livret Mathématique'];

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
  };


  const handleCampusSelection = (campusItem, ecoleItem, livretSelected) =>{
 
    if(livretSelected === 'Livret Motivation'){
      navigate(`/candidats?campus=${encodeURIComponent(campusItem)}&ecole=${encodeURIComponent(ecoleItem)}`);
    }else if(livretSelected === 'Livret Anglais'){
      navigate(`/anglais?campus=${encodeURIComponent(campusItem)}&ecole=${encodeURIComponent(ecoleItem)}`);
    }else if(livretSelected === 'Livret Biologie'){
      navigate(`/biologie?campus=${encodeURIComponent(campusItem)}&ecole=${encodeURIComponent(ecoleItem)}`);
    }else if(livretSelected === 'Livret Mathématique'){
      navigate(`/math?campus=${encodeURIComponent(campusItem)}&ecole=${encodeURIComponent(ecoleItem)}`);
    }else if(livretSelected === 'Administration' && ecoleItem === 'STL'){
      navigate(`/administrationSTL?campus=${encodeURIComponent(campusItem)}&ecole=${encodeURIComponent(ecoleItem)}`);
    }else if(livretSelected === 'Administration'){
      navigate(`/administration?campus=${encodeURIComponent(campusItem)}&ecole=${encodeURIComponent(ecoleItem)}`);
    }

  };


  useEffect(() => {
    async function recupToken(){
        if (!accessToken) {
          const account = msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }
    
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequestTeams,
            account: account
        });
        setAccessToken(response.accessToken);
      }
    }
    recupToken() ;
  }, [accessToken]);


  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(process.env.REACT_APP_URL_STRAPI + 'ecoles/?populate=*', {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
          },
        });
  
        setEcole(response.data.data);

      } catch (error) {
        console.error('Une erreur s\'est produite :', error);
      }
    }
    fetchData();

  }, []);


  const openSharePointSite = () => {
    
    const sharePointSiteUrl = process.env.REACT_APP_URL_SHAREPOINT_PORTAIL_GLOBAL ;  
    window.open(sharePointSiteUrl, '_blank');

  };


  const handleMenuItemClick = (RG) => {

    if(RG === 'RG'){
      navigate(`/rg`);
    }
    handleCloseUserMenu();
  };


  return (
    <div>
    <AppBar >
      {accessToken ? (
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>            
                  <IconButton onClick={toggleDrawer(true)} sx={{ p: 0 }}>
                      <Avatar alt="Logo Concours Advance" src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ8EYQBopDKjeJE9uHqMuI0ZEi00wkiUL_OeLIwK3xs3ov5zIfd" style={{ width: '100px', height: '100%' }}/>
                  </IconButton>
                <Drawer open={open} onClose={toggleDrawer(false)}>
                  <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
                    <List>
                      <ListItem>
                        <ListItemButton onClick={() => handleMenuItemClick('out')}>
                          <ListItemIcon>
                            <Typography sx={{ fontSize: 13 }} textAlign="center">
                              <SignOutButton />
                            </Typography>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      <ListItem>
                        <ListItemButton onClick={() => handleMenuItemClick("RG")}>
                          <ListItemIcon>
                            <Typography sx={{ fontSize: 18 }} textAlign="center">
                              Résultats Généraux
                            </Typography>
                          </ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                      {ecole.map((infoEcole, index) => (
                        <Accordion key={infoEcole.id} onClick={(e) => e.stopPropagation()}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`sub-menu-${infoEcole.id}`}
                            id={`sub-menu-${infoEcole.id}-header`}
                          >
                            <Typography>{infoEcole.attributes.Etablissement}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List>
                              {infoEcole.attributes.campuses.data.map(campus => (
                                <div key={campus.id}>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={`sub-menu-${campus.id}`}
                                      id={`sub-menu-${campus.id}-header`}
                                    >
                                      <Typography>{campus.attributes.CampusName}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <List>
                                        {infoEcole.attributes.Etablissement === "STL" ? (
                                          livretType.map((livret) => (
                                            <ListItem>
                                              <ListItemButton onClick={() => handleCampusSelection(campus.attributes.CampusName, infoEcole.attributes.Etablissement, livret)}>
                                                <ListItemIcon>
                                                  <Typography>{livret}</Typography>
                                                </ListItemIcon>
                                              </ListItemButton>
                                            </ListItem>
                                          ))
                                        ) : (
                                        <ListItem>
                                          <ListItemButton onClick={() => handleCampusSelection(campus.attributes.CampusName, infoEcole.attributes.Etablissement, 'Livret Motivation')}>
                                            <ListItemIcon>
                                              <Typography>Livret Motivation</Typography>
                                            </ListItemIcon>
                                          </ListItemButton>
                                        </ListItem>
                                        )}
                                        <ListItem>
                                          <ListItemButton onClick={() => handleCampusSelection(campus.attributes.CampusName, infoEcole.attributes.Etablissement, 'Administration')}>
                                            <ListItemIcon>
                                              <Typography>Administration</Typography>
                                            </ListItemIcon>
                                          </ListItemButton>
                                        </ListItem>
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </List>
                  </Box>
                </Drawer>
            </Box>
            <Box sx={{ ml: 5 }}>
                <Typography sx={{ fontSize: 13 }}>
                  Bienvenue {`${Name}`}
                </Typography>
                <Typography sx={{ fontSize: 10 }}>
                  ( {`${Mail}`})
                </Typography>
            </Box>
            <Box sx={{ ml: 25 }}>
              <Typography sx={{ fontSize: 20 }}>
                  Vous êtes actuellement sur {searchSchool} {searchCamp}.
              </Typography>
            </Box>
            <Box sx={{ ml: 40 }}>
              <IconButton onClick={openSharePointSite}   sx={{ fontSize: 15 }} color="inherit">Sharepoint</IconButton>
            </Box>
           
        </Toolbar>
      </Container> 
      ) : (
        <button onClick={() => msalInstance.loginRedirect()}>Se connecter</button>
      )}
    </AppBar> 

    </div>
  );
}
export default SuperAdminMenu;