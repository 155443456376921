import React, { useState } from 'react';
import {Grid,Button, FormControl, InputLabel, MenuItem, Select, Checkbox, TextField, Box } from '@mui/material';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));



const FormAnglais =  (data) => {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [grammarRating, setGrammarRating] = useState('');
  const [vocabularyRating, setVocabularyRating] = useState('');
  const [comprehensionRating, setComprehensionRating] = useState('');
  const [oralExpressionRating, setOralExpressionRating] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [classeEuropeenne, setClasseEuropeenne] = useState(false);
  const [livreAnglais, setLivreAnglais] = useState(false);
  const [serieAnglais, setSerieAnglais] = useState(false);
  const [parentBilingue, setParentBilingue] = useState(false);
  const [sejourLinguistique, setSejourLinguistique] = useState(false);
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false); 
  const navigate = useNavigate();

  const examinateur = data.profileData.profileData.profileData.userData.displayName;
  const categories = ['', 'Anecdote', 'Fiction', 'Funny Story', 'Cover Letter', 'Opinion Article', 'Speech'];

  
    const handleCommentChange = (event) => {
      setComment(event.target.value);
    };

    const handleConfirmation = (proceed) => {
      setOpenConfirmation(false);
      if (proceed) {
          const sendStatut = {
              data:  {
                StatutAnglais: null
              }      
            };

          axios.put(process.env.REACT_APP_URL_STRAPI+'stls/'+data.searchID, sendStatut, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
            },
          })
          .then(response => {
              navigate('/anglais');
          })
          .catch(error => {
              console.error('Erreur lors de la requête :', error);
          });
      }
    };

  const handleAnnulationButton = () => {
      setOpenConfirmation(true); 
  };

  const handleSubmit = (event) => {

    event.preventDefault(); 
    if (!selectedCategory ||
      grammarRating === '' ||
      vocabularyRating === '' ||
      comprehensionRating === '' ||
      oralExpressionRating === '' ||
      !comment) {
        setError(true); 
        return;
    }

    const newData = {
      data :  { 
        ExaminateurAnglais : examinateur,
        Classe_europeenne : classeEuropeenne ,
        livre_anglais : livreAnglais,
        Serie_anglais : serieAnglais,
        parent_bilingue : parentBilingue,
        Sejour_linguistique : sejourLinguistique,
        Type_sujet : selectedCategory ,
        commentaireAnglais: comment,
        note_Anglais_grammaire : grammarRating,
        note_Anglais_vocabulaire : vocabularyRating,
        note_Anglais_comprehension : comprehensionRating,
        note_Anglais_expression : oralExpressionRating, 
        noteAnglais : calculateTotalRating(),
        StatutAnglais: 'noté',

      } 
    };
    
    const url = process.env.REACT_APP_URL_STRAPI+'stls/'+data.searchID
  
          axios.put(url, newData, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
            },
          })
          .then(response => {
              navigate('/anglais');
              
          })
          .catch(error => {
              console.error('Erreur lors de la requête :', error);
          });
    
  }

  const calculateTotalRating = () => {
    return Number(grammarRating) + Number(vocabularyRating) + Number(comprehensionRating) + Number(oralExpressionRating);
  };


  return (
    <div>
      <Grid container align="center" spacing={5}>
        <Grid item xs={2}>
          <InputLabel>Classe européenne</InputLabel>
            <Checkbox
                checked={classeEuropeenne}
                onChange={() => setClasseEuropeenne(!classeEuropeenne)}
                color="primary"
            />
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Livres anglais</InputLabel>
            <Checkbox
                checked={livreAnglais}
                onChange={() => setLivreAnglais(!livreAnglais)}
                color="primary"
            />
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Séries anglais</InputLabel>
            <Checkbox
                checked={serieAnglais}
                onChange={() => setSerieAnglais(!serieAnglais)}
                color="primary"
            />
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Parent bilingue</InputLabel>
            <Checkbox
                checked={parentBilingue}
                onChange={() => setParentBilingue(!parentBilingue)}
                color="primary"
            />
        </Grid>
        <Grid item xs={2}>
          <InputLabel>Séjours linguistiques</InputLabel>
            <Checkbox
                checked={sejourLinguistique}
                onChange={() => setSejourLinguistique(!sejourLinguistique)}
                color="primary"
            />
        </Grid>
      </Grid>
      <FormControl fullWidth>
        <InputLabel>Catégorie</InputLabel>
        <Select
            label = "Type de Sujet"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            error={error && !selectedCategory}
        >
          {categories.map((category, index) => (
            <MenuItem key={index} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell >Grammaire /5</StyledTableCell>
            <StyledTableCell >Vocabulaire /5</StyledTableCell>
            <StyledTableCell >Compréhension /5</StyledTableCell>
            <StyledTableCell >Expression Orale</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <StyledTableRow>
              <StyledTableCell>
                exercice 1
              </StyledTableCell>
              <StyledTableCell>
                <Select
                    value={grammarRating}
                    onChange={(e) => setGrammarRating(e.target.value)}
                    error={error && !grammarRating}
                >
                    {['0', '1', '2', '3', '4', '5'].map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                    ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell >
                <Select
                    value={vocabularyRating}
                    onChange={(e) => setVocabularyRating(e.target.value)}
                    error={error && !vocabularyRating}
                >
                    {['0', '1', '2', '3', '4', '5'].map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                    ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell >
                <Select
                    value={comprehensionRating}
                    onChange={(e) => setComprehensionRating(e.target.value)}
                    error={error && !comprehensionRating}
                    >
                    {['0', '1', '2', '3', '4', '5'].map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                    ))}
                </Select> 
              </StyledTableCell>
              <StyledTableCell >
                <Select
                    value={oralExpressionRating}
                    onChange={(e) => setOralExpressionRating(e.target.value)}
                    error={error && !oralExpressionRating}
                >
                    {['0', '1', '2', '3', '4', '5'].map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                    ))}
                </Select>
              </StyledTableCell>
            </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <Box mt={2}>
    <TextField
        label="Note Finale"
        variant="outlined"
        value={calculateTotalRating()}
        InputProps={{ readOnly: true }}
    />
    </Box>
    <Box mt={2}>
    <TextField
        label="Commentaires"
        multiline
        rows={12}
        value={comment}
        onChange={handleCommentChange}
        variant="outlined"
        fullWidth
        error={error && !comment}
    />
    </Box>
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
            <Button onClick={handleAnnulationButton}  color="error" variant="outlined" sx={{ mt: 2 }}>
                Annuler
            </Button>
            <Dialog open={openConfirmation} onClose={() => handleConfirmation(false)}>
                <DialogTitle>ATTENTION</DialogTitle>
                <DialogContent>
                    <p>Êtes-vous sûr de vouloir annuler ?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmation(false)} color="primary">
                        Non
                    </Button>
                    <Button onClick={() => handleConfirmation(true)} color="primary">
                        Oui
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
        <Grid item xs={6}>
            <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>
                Valider
            </Button>
        </Grid>
      </Grid>
      </Box>
    </div>
  );
};

export default FormAnglais;










