import React from 'react';
import Math from './ListePassage/ListePassageMath'; 
import Motivation from './ListePassage/ListePassageMotivation';
import Biologie from './ListePassage/ListePassageBiologie';
import Anglais from './ListePassage/ListePassageAnglais';
 

const ListePassage = () => {


  return (
    <div>
        <Math/> 
        <Motivation />
        <Biologie />
        <Anglais />
    </div>
  );
};

export default ListePassage;
