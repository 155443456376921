import React from 'react';
import { Box, Typography} from '@mui/material';
  

export const ErrorPage = () => {
    
  
  return (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
    >
    <Box mb={2}>
        <Typography variant="h4" align="center">
            Accès refusé  
        </Typography>
        </Box>
        <Typography variant="body1" align="center">
            Vous n'avez pas les droits d'accès à cette page.
        </Typography>
    </Box>
  );
}

export default ErrorPage;