import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ButtonGroup from '@mui/material/ButtonGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ListeAbsentAnglais = (profileData) => {
  const [candidats, setCandidats] = useState([]);
  const campus = 'paris'

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_URL_STRAPI+'stls', {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
        },
      })
      .then((response) => {
        
        const currentDate = new Date().toISOString().split('T')[0]; 

        const filteredCandidats = response.data.data.filter((candidat) => {
          const candidatConvocation = candidat.attributes.convocation.split('T')[0];
          const isAbsent = candidat.attributes.StatutAnglais === 'absent'; // Vérifie le statut "en attente"
          const campusPassage = candidat.attributes.campus === campus;
          return candidatConvocation === currentDate && isAbsent  && campusPassage;
        });

        setCandidats(filteredCandidats);
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, [campus]);

  
  //fonction pour réintegrer le candidat
  const  handleButtonBack = (idPresent) => {
   
    const newData = {
      data:  {
         StatutAnglais: null
      }
       
    };

    const url = process.env.REACT_APP_URL_STRAPI+'stls/'
    const candidatID = idPresent;

    axios.put(url+candidatID, newData, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`, // Remplacez votreToken par votre token d'authentification
      },
    })
    .then(response => {
          
        window.location.reload() 
    })
    .catch(error => {
        console.error('Erreur lors de la requête :', error);
    });
          
  };


  return (
    <div>
        <TableContainer component={Paper} align='center'>
          <Typography component="h1" variant="h5" align="center">
             Liste Absent Anglais
          </Typography>   
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell >Numéro Parcoursup</StyledTableCell>
                <StyledTableCell  align="center">Civilité</StyledTableCell>
                <StyledTableCell  align="center">Nom du candidat</StyledTableCell> 
                <StyledTableCell  align="center">Réintègrer le candidat</StyledTableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              {candidats.map((candidat) => (
                <StyledTableRow key={candidat.attributes.parcoursup}>
                    <StyledTableCell component="th" scope="candidat">{candidat.attributes.parcoursup}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.civilite}</StyledTableCell>
                    <StyledTableCell align="center">{candidat.attributes.prenom} {candidat.attributes.nom}</StyledTableCell>
                    <StyledTableCell align="center">
                    <ButtonGroup  variant="contained">
                            <Button  onClick={() => handleButtonBack(candidat.id)}>Réintègrer</Button>
                        </ButtonGroup>
                    </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
};

export default ListeAbsentAnglais;